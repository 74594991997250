import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable, from, BehaviorSubject } from 'rxjs';
import { auth } from 'firebase/app';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private _isLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public userState: Observable<boolean> = this._isLoggedIn.asObservable();

  constructor(public afAuth: AngularFireAuth) {
    
    this.afAuth.authState.subscribe(
      (user) => {
        console.log(user);
          if(user) {
              this._isLoggedIn.next(true);
          }else {
              this._isLoggedIn.next(false);
          }
      }
  );
  }

  login(email: string, password: string): Observable<firebase.auth.UserCredential> {
    return from(this.afAuth.auth.signInWithEmailAndPassword(email, password));
  }
}
