import { Observer, ReplaySubject, Observable } from "rxjs";
import { Injectable } from "@angular/core";

// see https://stackoverflow.com/a/12709880/1203690
declare global {
  interface Window {
    hubReady: Observer<boolean>;
  }
}

@Injectable()
export class MathServiceImpl {
  private readonly notifier: ReplaySubject<boolean>;

  constructor() {
    this.notifier = new ReplaySubject<boolean>();
    window.hubReady = this.notifier; // as said, bind to window object
  }

  ready(): Observable<boolean> {
    return this.notifier;
  }

  render(element: HTMLElement): void {
    
    MathJax.Hub.Queue(['Typeset', MathJax.Hub, element]);
    // MathJax.Hub.Queue(()=>{
    //   setTimeout(()=>element.style.opacity = '1', 0);
    // });
  }
}