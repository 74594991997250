import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { AuthService } from 'app/authentication/auth.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';

@Component({
    selector     : 'login-2',
    templateUrl  : './login-2.component.html',
    styleUrls    : ['./login-2.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class Login2Component implements OnInit
{
    loginForm: FormGroup;
    

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        private authService: AuthService,
        private _matSnackBar: MatSnackBar,
        private router: Router
    )
    {
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar   : {
                    hidden: true
                },
                toolbar  : {
                    hidden: true
                },
                footer   : {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        this.loginForm = this._formBuilder.group({
            email   : ['', [Validators.required, Validators.email]],
            password: ['', Validators.required]
        });

        this.authService.userState.subscribe(
            (loggedIn) => {
                console.log("Subss::",loggedIn);
            }
        )

        
    }

    login(): void {
        const email = this.loginForm.get('email').value;
        const password = this.loginForm.get('password').value;
        

        this.authService.login(email, password).subscribe((user) => {
            console.log(user);
            this.router.navigate(['/practice-dashboard']);

            setTimeout(() => {
                 // Show the success message
                this._matSnackBar.open('Login Success', null, {
                    verticalPosition: 'bottom',
                    duration        : 3000
                });
            }, 10);
           
        }, (error) => {
            console.log(error);
             // Show the success message
             this._matSnackBar.open('Login Error', null, {
                verticalPosition: 'bottom',
                duration        : 3000
            });
            
        }
        )

    }
}
