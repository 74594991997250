export class ContactsFakeDb
{
    public static contacts = [
        {
            "id": "57a967e6f570826e5eec384d",
            "name": "01-ELECTROSTATICS"
          },
          {
            "id": "57a967e6f570826e5eec384e",
            "name": "02-CURRENT ELECTRICITY"
          },
          {
            "id": "57a967e6f570826e5eec384f",
            "name": "03-EFFECTS OF ELECTRIC CURRENT"
          },
          {
            "id": "57a967e6f570826e5eec3850",
            "name": "04-ELECTROMAGNETIC INDUCTION AND ALTERNATING CURRENT"
          },
          {
            "id": "57a967e6f570826e5eec3851",
            "name": "05-ELECTROMAGNETIC WAVES AND WAVE OPTICS"
          },
          {
            "id": "57a967e6f570826e5eec3852",
            "name": "06-ATOMIC PHYSICS"
          },
          {
            "id": "57a967e6f570826e5eec3853",
            "name": "07-DUAL NATURE OF RADIATION AND MATTER AND RELATIVITY"
          },
          {
            "id": "57a967e6f570826e5eec3854",
            "name": "08-NUCLEAR PHYSICS"
          },
          {
            "id": "57a967e6f570826e5eec3855",
            "name": "09-SEMICONDUCTOR DEVICES AND THEIR APPLICATIONS"
          },
          {
            "id": "57a967e6f570826e5eec3856",
            "name": "10-COMMUNICATION SYSTEMS"
          }
    ];

    public static user = [
        {
            'id'              : '5725a6802d10e277a0f35724',
            'name'            : 'John Doe',
            'avatar'          : 'assets/images/avatars/profile.jpg',
            'starred'         : [
                '57a967e6f570826e5eec3855',
                '57a967e6f570826e5eec3856',
                '57a967e6f570826e5eec384f',
            ],
            'frequentContacts': [
                '57a967e6f570826e5eec3855',
                '57a967e6f570826e5eec3856',
                '57a967e6f570826e5eec384f'
            ],
            'groups'          : [
                {
                    'id'        : '5725a6802d10e277a0f35739',
                    'name'      : 'Friends',
                    'contactIds': [
                        '5725a680bbcec3cc32a8488a',
                        '5725a680e87cb319bd9bd673',
                        '5725a6802d10e277a0f35775'
                    ]
                },
                {
                    'id'        : '5725a6802d10e277a0f35749',
                    'name'      : 'Clients',
                    'contactIds': [
                        '5725a680cd7efa56a45aea5d',
                        '5725a68018c663044be49cbf',
                        '5725a6809413bf8a0a5272b1',
                        '5725a6803d87f1b77e17b62b'
                    ]
                },
                {
                    'id'        : '5725a6802d10e277a0f35329',
                    'name'      : 'Recent Workers',
                    'contactIds': [
                        '5725a680bbcec3cc32a8488a',
                        '5725a680653c265f5c79b5a9',
                        '5725a6808a178bfd034d6ecf',
                        '5725a6801146cce777df2a08'
                    ]
                }
            ]
        }
    ];
}
