import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
    {
        id       : 'practice',
        title    : 'Practice',
        translate: 'NAV.PRACTICE',
        type     : 'item',
        icon    : 'import_contacts',
        url: '/practice-dashboard',
        children : undefined
    },
    {
        id       : 'self',
        title    : 'Self Evaluate',
        translate: 'NAV.SELF_EVALUATE',
        type     : 'item',
        icon    : 'pages',
        url: '/self-evaluate?evaluate=true',
        externalUrl: true,
        children : undefined
    },
    {
        id       : 'logout',
        title    : 'Logout',
        translate: 'NAV.LOGOUT',
        type     : 'item',
        icon    : 'exit_to_app',
        url: '/logout',
        children : undefined
    }
];
