import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import {
    MatButtonModule, MatCheckboxModule, MatDatepickerModule, MatFormFieldModule, MatIconModule, MatInputModule, MatMenuModule, MatRippleModule, MatTableModule, MatToolbarModule
} from '@angular/material';
import { FuseSharedModule } from '@fuse/shared.module';

import { SelfEvaluateComponent } from './self-evaluate.component';
import { MediumService } from '../practice-dashboard/medium.service';
import { PracticeDashboardComponent } from '../practice-dashboard/practice-dashboard.component';

const routes = [
    {
        path     : 'self-evaluate',
        component: PracticeDashboardComponent,
        resolve  : {
            medium: MediumService
        }
    }
];

@NgModule({
    declarations: [
        SelfEvaluateComponent
    ],
    imports     : [
        RouterModule.forChild(routes),
        BrowserAnimationsModule,
        TranslateModule,
        //Material imports
        MatButtonModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatRippleModule,
        MatTableModule,
        MatToolbarModule,

        FuseSharedModule
    ],
    exports     : [
        SelfEvaluateComponent
    ]
})

export class SelfEvaluateModule
{
}
