import { Directive, ElementRef, OnInit, AfterViewInit, OnDestroy, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { MathServiceImpl } from './mathjax.service';
import { take, takeUntil } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';

@Directive({
  selector: '[appMathjax]'
})
export class MathDirective implements OnInit, OnDestroy, AfterViewInit {
  private alive$ = new Subject<boolean>();

  @Input()
  private appMathjax: string;

  @Input()
  private index: number;
  private _el: HTMLElement;

  constructor(private service: MathServiceImpl,
              private el: ElementRef, private sanitizer: DomSanitizer) {
    this._el = el.nativeElement as HTMLElement;
  }

  ngOnInit(): void {
    // this._el.style.opacity = '0';
  }

  ngAfterViewInit() {
    let val = this.appMathjax;

    val = val ? val.replace(/\\\(/g, '$') : '';
    val = val.replace(/\\\)/g, '$');

    let opString = '';

    if (this.index >= 0) {
      opString = '<span class="opString">(' + String.fromCharCode(97 + this.index) + ') </span> <div style="display: inline-block;">';
    };

    
    this._el.innerHTML = opString + val + '</div>';

    this.service
      .ready()
      .pipe(
        take(1),
        takeUntil(this.alive$)
      ).subscribe(res => {
        this.service.render(this._el);
    });
  }
  

  ngOnDestroy(): void {
    this.alive$.next(false);
  }
}