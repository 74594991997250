import { FuseUtils } from '@fuse/utils';

export class Chapter
{
    id: string;
    name: string;

    /**
     * Constructor
     *
     * @param chapter
     */
    constructor(chapter)
    {
        {
            this.id = chapter.id || FuseUtils.generateGUID();
            this.name = chapter.name || '';
        }
    }
}
