import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { MatButtonModule, MatFormFieldModule, MatIconModule, MatInputModule, MatSelectModule, MatRadioModule, MatOptionModule } from '@angular/material';

import { FuseSharedModule } from '@fuse/shared.module';

import { AcademyCoursesComponent } from 'app/pages/test/courses/courses.component';
import { AcademyCourseComponent } from 'app/pages/test/course/course.component';
import { AcademyCoursesService } from 'app/pages/test/courses.service';
import { AcademyCourseService } from 'app/pages/test/course.service';
import { FuseSidebarModule } from '@fuse/components';
import { MatExpansionModule } from '@angular/material/expansion';
import { MathModule } from 'app/mathjax.module';
import { ResultsComponent } from './results/results.component';

const routes = [
    // {
    //     path     : 'courses',
    //     component: AcademyCoursesComponent,
    //     resolve  : {
    //         academy: AcademyCoursesService
    //     }
    // },
    {
        path     : 'courses',
        component: AcademyCourseComponent,
        resolve  : {
            questions: AcademyCoursesService
        }
    } 
];

@NgModule({
    declarations: [
        AcademyCoursesComponent,
        AcademyCourseComponent,
        ResultsComponent
    ],
    imports     : [
        RouterModule.forChild(routes),

        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatSelectModule,
        MatExpansionModule,
        MatOptionModule,
        MatRadioModule,

        FuseSharedModule,
        FuseSidebarModule,
        MathModule.forRoot()
    ],
    providers   : [
        AcademyCoursesService,
        AcademyCourseService
    ]
})
export class AcademyModule
{
}
