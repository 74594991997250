export const locale = {
    lang: 'en',
    data: {
        'NAV': {
            'DASHBOARD': 'Dashboard',
            'PRACTICE':'Practice',
            'COMPETE':'Compete',
            'SELF_EVALUATE':'Self Evaluate',
            'LOGOUT': 'Logout'
        }
    }
};
