import { Component } from '@angular/core';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { fuseAnimations } from '@fuse/animations';

import { locale as english } from './i18n/en';
import { locale as turkish } from './i18n/tr';

import {environment} from '../../../environments/environment';

@Component({
    selector   : 'self-evaluate',
    templateUrl: './self-evaluate.component.html',
    styleUrls  : ['./self-evaluate.component.scss'],
    animations: fuseAnimations
})
export class SelfEvaluateComponent
{
    public version: string = environment.VERSION;
    /**
     * Constructor
     *
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     */
    constructor(
        private _fuseTranslationLoaderService: FuseTranslationLoaderService
    )
    {
        this._fuseTranslationLoaderService.loadTranslations(english, turkish);
    }
}
