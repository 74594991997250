import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule, MatIconModule, MatOptionModule, MatRadioModule, MatSnackBarModule } from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';
import 'hammerjs';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';
import { FakeDbService } from 'app/fake-db/fake-db.service';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { PracticeDashboardModule } from 'app/pages/practice-dashboard/practice-dashboard.module';
import { PracticeSubjectModule } from 'app/pages/practice-subject/practice-subject.module';
import {  SelfEvaluateModule } from 'app/pages/self-evaluate/self-evaluate.module';

import { ContactsModule } from 'app/pages/chapter/chapter.module';
import { InMemoryWebApiModule } from 'angular-in-memory-web-api';
import { AcademyModule } from './pages/test/academy.module';
import { Login2Module } from './pages/authentication/login-2/login-2.module';
import { RegisterModule } from './pages/authentication/register/register.module';
import { ForgotPasswordModule } from './pages/authentication/forgot-password/forgot-password.module';

import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";

const firebaseConfig = {
    apiKey: "AIzaSyDgQum_oLyawZxCzUtVk0ELwT4CBH0k6u0",
    authDomain: "toppers-corner-uat.firebaseapp.com",
    databaseURL: "https://toppers-corner-uat.firebaseio.com",
    projectId: "toppers-corner-uat",
    storageBucket: "toppers-corner-uat.appspot.com",
    messagingSenderId: "64027720340",
    appId: "1:64027720340:web:e7fab217f2a6e675"
};

const appRoutes: Routes = [
    {
        path      : '**',
        redirectTo: 'login'
    }
];

@NgModule({
    declarations: [
        AppComponent,
        // MathjaxDirective
    ],
    imports     : [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes),

        TranslateModule.forRoot(),
        InMemoryWebApiModule.forRoot(FakeDbService, {
            delay             : 0,
            passThruUnknownUrl: true
        }),

        //Firebase
        AngularFireModule.initializeApp(firebaseConfig),
        AngularFireAuthModule,

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,
        MatOptionModule,
        MatRadioModule,
        MatSnackBarModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // App modules
        LayoutModule,
        PracticeDashboardModule,
        PracticeSubjectModule,
        SelfEvaluateModule,
        ContactsModule,
        AcademyModule,
        Login2Module,
        RegisterModule,
        ForgotPasswordModule
    ],
    bootstrap   : [
        AppComponent
    ]
})
export class AppModule
{
}
