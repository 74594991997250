import { Component, OnInit } from '@angular/core';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { fuseAnimations } from '@fuse/animations';

import { locale as english } from './i18n/en';
import { locale as turkish } from './i18n/tr';

import {environment} from '../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector   : 'practice-dashboard',
    templateUrl: './practice-dashboard.component.html',
    styleUrls  : ['./practice-dashboard.component.scss'],
    animations: fuseAnimations
})
export class PracticeDashboardComponent implements OnInit
{
    public version: string = environment.VERSION;
    public classes: Array<any> = [];
    public evaluateMode = false;
    public title = '';
    /**
     * Constructor
     *
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     */
    constructor(
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private activatedRoute: ActivatedRoute,
        private router: Router
    )
    {
        this._fuseTranslationLoaderService.loadTranslations(english, turkish);

        // console.log(this.activatedRoute.snapshot.data.medium[0].classes);

    }

    ngOnInit() {
        const mediumData = this.activatedRoute.snapshot.data.medium;
        this.classes = mediumData.reduce((acc, curr) => acc.concat(curr.classes), []);
        console.log(this.classes);
        this.activatedRoute.queryParams
            .subscribe(params => {
                this.evaluateMode = params.evaluate === 'true' ? true: false;

                this.title = !this.evaluateMode ? "Practice" : "Self-Evaluate";
            });
    }

    gotoSubject(className: string, classId: string) {
        // this.router.navigateByUrl(`/practice-subject/neet/${classId}`);
        this.router.navigate(['/practice-subject', className, classId], { queryParams: { evaluate: this.evaluateMode } });
    }
}
