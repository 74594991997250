import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import {
    MatButtonModule, MatCheckboxModule, MatDatepickerModule, MatFormFieldModule, MatIconModule, MatInputModule, MatMenuModule, MatRippleModule, MatTableModule, MatToolbarModule
} from '@angular/material';
import { FuseSharedModule } from '@fuse/shared.module';

import { PracticeSubjectComponent } from './practice-subject.component';
import { GetSubjectService } from '../practice-dashboard/get-subject.service';

const routes = [
    {
        path     : 'practice-subject/:exam/:id',
        component: PracticeSubjectComponent,
        resolve  : {
            classes: GetSubjectService
        }
    }
];

@NgModule({
    declarations: [
        PracticeSubjectComponent
    ],
    imports     : [
        RouterModule.forChild(routes),
        BrowserAnimationsModule,
        TranslateModule,
        //Material imports
        MatButtonModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatRippleModule,
        MatTableModule,
        MatToolbarModule,

        FuseSharedModule
    ],
    exports     : [
        PracticeSubjectComponent
    ]
})

export class PracticeSubjectModule
{
}
