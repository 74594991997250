import { Component, OnInit } from '@angular/core';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { fuseAnimations } from '@fuse/animations';

import { locale as english } from './i18n/en';
import { locale as tamil } from './i18n/ta';

import {environment} from '../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector   : 'practice-subject',
    templateUrl: './practice-subject.component.html',
    styleUrls  : ['./practice-subject.component.scss'],
    animations: fuseAnimations
})
export class PracticeSubjectComponent implements OnInit
{
    public version: string = environment.VERSION;
    public exam: string = '';
    public subjects: any;
    public evaluateMode = false;
    public title = '';
    /**
     * Constructor
     *
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     */
    constructor(
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private route: ActivatedRoute,
        private router: Router
    )
    {
        this._fuseTranslationLoaderService.loadTranslations(english, tamil);
        this.exam = this.route.snapshot.paramMap.get('exam').toUpperCase();
    }

    ngOnInit() {
        this.subjects = this.route.snapshot.data.classes['subjects'];

        this.route.queryParams
        .subscribe(params => {
            this.evaluateMode = params.evaluate === 'true' ? true: false;

            this.title = !this.evaluateMode ? "Practice" : "Self-Evaluate";
        });
    }

    gotoChapter(exam, subName, subId) {
        // outerLink="/choose-chapter/{{exam}}/{{sub.name}}/{{sub.id}}"
        this.router.navigate(['/choose-chapter', exam, subName, subId], { queryParams: { evaluate: this.evaluateMode } });

    }
}
