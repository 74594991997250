import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.scss']
})
export class ResultsComponent implements OnInit {
  @Input() responses: any;

  @Output()
  reviewWrongAnswer: EventEmitter<boolean>;

  public score: number;
  public total: number;
  constructor() {
    this.reviewWrongAnswer = new EventEmitter();
   }

  ngOnInit() {
   console.log(this.responses);
   this.calculateScore();
  }


  calculateScore() {
    this.total = this.responses.length;
    this.score = this.responses
      .filter(question => question.options.some(option => (option.answer === true) && (option.clicked === true)))
      .length;
  }
 

}
