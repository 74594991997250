import {ModuleWithProviders, NgModule} from '@angular/core';
import { MathServiceImpl } from './mathjax.service';
import { MathDirective } from './mathjax.directive';

@NgModule({
  declarations: [MathDirective],
  exports: [MathDirective]
})
export class MathModule {
  constructor(mathService: MathServiceImpl) {
    console.log(`constructor module`);
    // see https://docs.mathjax.org/en/latest/advanced/dynamic.html
    const script = document.createElement('script') as HTMLScriptElement;
    script.type = 'text/javascript';
    script.src = 'https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.5/MathJax.js?config=TeX-MML-AM_CHTML';
    script.async = true;

    

    const config = document.createElement('script') as HTMLScriptElement;
    config.type = 'text/x-mathjax-config';
    config.text = `
    MathJax.Hub.Config({
        messageStyle: "none",
        tex2jax: {preview: "none"},
        showMathMenu: false,
        showMathMenuMSIE: false,
        processClass: "math-tex",
        tex2jax: {inlineMath: [['$','$']]}
    });
      MathJax.Hub.Register.StartupHook('End', () => {
        window.hubReady.next();
        window.hubReady.complete();
      });
    `;

    document.getElementsByTagName('head')[0].appendChild(config);
    document.getElementsByTagName('head')[0].appendChild(script);
  }


  public static forRoot(): ModuleWithProviders {
    return {
      ngModule: MathModule,
      providers: [{provide: MathServiceImpl, useClass: MathServiceImpl}]
    };
  }
}

