import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class MediumService implements Resolve<any>
{
    private cache:Observable<any>;
    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient
    )
    {
       this.cache = null;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        // NOTE: Medium Ids are hard coded for now
        // only fetch two mediums ENTRANCE(5992e9c52bdaef3206ea19e6) and TN-ENGLISH(57a9603cf570826e5eec3836)

        return this.cache ?
            this.cache :
            this.cache = this._httpClient.get('https://www.easyqapi.co.in/medium?where={"id":["5992e9c52bdaef3206ea19e6","57a9603cf570826e5eec3836"]}');
    }
}
