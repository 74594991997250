import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import {
    MatButtonModule, MatCheckboxModule, MatDatepickerModule, MatFormFieldModule, MatIconModule, MatInputModule, MatMenuModule, MatRippleModule, MatTableModule, MatToolbarModule
} from '@angular/material';
import { FuseSharedModule } from '@fuse/shared.module';

import { PracticeDashboardComponent } from './practice-dashboard.component';
import { MediumService } from './medium.service';
import { GetSubjectService } from './get-subject.service';
import { GetChapterService } from './get-chapter.service';

const routes = [
    {
        path     : 'practice-dashboard',
        component: PracticeDashboardComponent,
        resolve  : {
            medium: MediumService
        }
    }
];

@NgModule({
    declarations: [
        PracticeDashboardComponent
    ],
    imports     : [
        RouterModule.forChild(routes),
        BrowserAnimationsModule,
        TranslateModule,
        //Material imports
        MatButtonModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatRippleModule,
        MatTableModule,
        MatToolbarModule,

        FuseSharedModule
    ],
    exports     : [
        PracticeDashboardComponent
    ],
    providers   : [
        MediumService,
        GetSubjectService,
        GetChapterService
    ]
})

export class PracticeDashboardModule
{
}
