export const locale = {
    lang: 'ta',
    data: {
        'NAV': {
            'DASHBOARD': 'Dashboard',
            'PRACTICE':'Practice',
            'COMPETE':'Compete'
        }
    }
};
