import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class AcademyCoursesService implements Resolve<any>
{
    onCategoriesChanged: BehaviorSubject<any>;
    onCoursesChanged: BehaviorSubject<any>;

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient
    )
    {
        // Set the defaults
        this.onCategoriesChanged = new BehaviorSubject({});
        this.onCoursesChanged = new BehaviorSubject({});
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {   
        console.log(route.paramMap.get('chapterId'));
        console.log(route);
        console.log(state);
        
        const chapterId = route.paramMap.get('chapterId');
        const chapterIdArr = [];

        if(chapterId.indexOf(',') < 0)
        {
            // do nothing
            chapterIdArr.push(chapterId);
        } else {
            chapterIdArr.concat(chapterId.split(','));
        }

        return this.getCourses(chapterIdArr);
    }

    /**
     * Get courses
     *
     * @returns {Promise<any>}
     */
    getCourses(chapterId, limit=50): any
    {
        
        let payload: any = {
            chapter: chapterId[0],
            // chapter: '57b1e8bd60d8425021fd666b',
            limit: limit
        }

        // /question/random
        return this._httpClient.post(`https://easyqapi.co.in/question/random`, payload);
        // return this._httpClient.post(`http://localhost:2337/question/random`, payload);
        
    }

}
